<template>
    <base-dialog-form
        :dialog="dialog"
        :loading="loading"
        title="Create Value Chain Output"
        actionText="Save details"
        @close="$emit('close')"
        @submit="submit()"
    >
        <template #content>
        <v-text-field
            dense
            outlined
            persistent-hint
            label="Value Chain Output Name *"
            class="rounded-lg"
            v-model="valueChainOutputObj.name"
            :hint="errors.get('name')"
            :error="errors.has('name')"
            @input="errors.clear('name')"
        ></v-text-field>

        <v-text-field
            dense
            outlined
            persistent-hint
            label="Description"
            class="rounded-lg"
            v-model="valueChainOutputObj.description"
            :hint="errors.get('description')"
            :error="errors.has('description')"
            @input="errors.clear('description')"
        ></v-text-field>

        </template>
    </base-dialog-form>
</template>
  
<script>
  import { mapActions, mapGetters } from 'vuex'
  import ValueChainOutput from '@/libs/silo/ValueChainOutput'
  
  export default {
    props: {
      dialog: {
        required: true,
        type: Boolean,
      },
  
      valueChainUid: {
        type: String,
        required: true
      }
    },
  
    data () {
      return {
        loading: false,
        valueChainOutputObj: new ValueChainOutput(),
      }
    },
  
    computed: {
      ...mapGetters({
      resources: 'getFarmResources',
    }),

      errors () {
        return this.valueChainOutputObj.form.errors
      },
    },
  
    methods: {
      ...mapActions([
        'setFarmResources',
      ]),
      submit () {
      if (!this.loading) {
        this.loading = true
        
        this.valueChainOutputObj.value_chain_uid = this.valueChainUid

        this.valueChainOutputObj.store().then(() => {
          this.setFarmResources().then(()=> {
            this.$emit('close')
          })
        })
        .finally(() => {
          this.loading = false
        })
      }
    },
  },
  
    mounted () {
      this.setResources();
    }
  }
  </script>